import { Checkbox } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { useTheme } from "../components/ThemeProvider";
import { Text } from "../components/typography";
import { initializeFeatures, Feature } from "../features/features";
import Styled from "styled-components";

initializeFeatures();

function StatefulCheckbox(props: { feature: Feature; flags?: Feature[] }) {
  const theme = useTheme();
  const { feature, flags } = props;
  const [checked, setChecked] = useState<boolean>(false);

  const isFeatureEnabled = (): Feature[] | undefined => {
    if (typeof window !== "undefined") {
      flags?.forEach((flag: Feature) => {
        if (flag.name === feature.name) {
          setChecked(flag.enabled);
        }
      });
    }
    return undefined;
  };

  const setFeatureToLocalStorage = () => {
    const newState = flags?.map((flag) => {
      if (flag.name === feature.name) {
        return {
          description: feature.description,
          enabled: checked || false,
          name: feature.name,
        };
      } else return flag;
    });
    localStorage.setItem("flags", JSON.stringify(newState));
  };

  function handleChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>) {
    setChecked(!checked);
  }

  useEffect(() => {
    isFeatureEnabled();
  }, []);

  useEffect(() => {
    setFeatureToLocalStorage();
  }, [checked]);

  const tableDataStyle: React.CSSProperties = {
    borderCollapse: "collapse",
  };
  return (
    <tr>
      <td style={tableDataStyle}>
        <Checkbox
          key={feature.name}
          label={feature.name}
          checked={checked}
          onChange={(e) => handleChange(e)}
          styles={{
            root: {
              margin: "10px 0px",
              ":hover .ms-Checkbox-checkbox": {
                borderColor: theme.semanticColors.bodyText,
                color: theme.semanticColors.bodyText,
                backgroundColor: checked
                  ? theme.semanticColors.buttonBackgroundHover
                  : "transparent",
              },
              ":hover .ms-Checkbox-text": {
                borderColor: theme.semanticColors.bodyText,
                color: theme.semanticColors.bodyText,
              },
              ":hover .ms-Checkbox-checkmark": {
                color: theme.semanticColors.bodyText,
              },
            },
            label: {},
            checkbox: {
              borderColor: theme.semanticColors.bodyText,
              backgroundColor: checked
                ? theme.semanticColors.buttonBackground
                : "transparent",
            },
            checkmark: {
              borderColor: theme.semanticColors.bodyText,
            },
            text: {
              color: theme.semanticColors.bodyText,
            },
          }}
        ></Checkbox>
      </td>
      <td style={tableDataStyle}>
        <Text variant="medium_C" style={{ marginLeft: 20 }}>
          {feature.description}
        </Text>
      </td>
    </tr>
  );
}

function FeaturePanel(props) {
  let flagsStrings = "";
  let flags: Feature[] | undefined = undefined;
  if (typeof window !== "undefined") {
    flagsStrings = window.localStorage.getItem("flags") || "{}";
    flags = JSON.parse(flagsStrings) as Feature[];
  }
  return (
    <Layout {...props}>
      <Text variant="large_C">Feature Panel</Text>
      <table>
        <tbody>
          {flags &&
            flags.map((feature) => (
              <StatefulCheckbox
                key={feature.name}
                feature={feature}
                flags={flags}
              />
            ))}
        </tbody>
      </table>
    </Layout>
  );
}

export default FeaturePanel;
