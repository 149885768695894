interface Feature {
  name: string;
  enabled: boolean;
  description: string;
}

const features: Array<Feature> = [
  {
    name: "draftMode",
    enabled: false,
    description:
      "when enabled it will show unpublished posts on the draft page",
  },
  {
    name: "testMode",
    enabled: false,
    description:
      "a test flag",
  },
];

const initializeFeatures = (featureProp: Feature[] = features) => {
  if (typeof window !== "undefined") {
    if (!localStorage.getItem("flags")) {
        localStorage.setItem("flags", JSON.stringify(features))
    }
  }
};

export { features, Feature, initializeFeatures };
